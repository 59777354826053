<div class="navbar">
  <img src="../../../../assets/logos/logo-urbe-sinfondo.gif" class="logo-urbe" alt="Urbe Logo" />

  <ng-container>
    <button mat-icon-button class="toggle-button" (click)="closeNavbar()">
      <mat-icon class="black">{{ isOpen ? "clear" : "menu" }}</mat-icon>
    </button>
    <div class="navbar-container">
      <ul class="navbar-items">
        <ng-container *ngFor="let navbar of displayedNavbar">
          <li
            [routerLink]="navbar.route"
            class="on-hover"
            (click)="closeNavbar()"
          >
            <a>{{ navbar.name }}</a>
          </li>
        </ng-container>
        <li>
          <button class="on-hover" mat-button [matMenuTriggerFor]="menu">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="user-alt"
              class="icon"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M256 288c79.5 0 144-64.5 144-144S335.5 0 256 0 112 64.5 112 144s64.5 144 144 144zm128 32h-55.1c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16H128C57.3 320 0 377.3 0 448v16c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48v-16c0-70.7-57.3-128-128-128z"
              ></path>
            </svg>
            {{ name }}
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="user-alt"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              class="icon"
            >
              <path
                d="M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z"
              />
            </svg>
          </button>
          <mat-menu #menu="matMenu">
            <button class="button" mat-menu-item (click)="signOut()">
              <mat-icon>exit_to_app</mat-icon>
              Cerrar Sesión
            </button>
          </mat-menu>
        </li>
      </ul>
    </div>
  </ng-container>
</div>

