<!-- Dialogo que contiene la informacion de la constancia a verificar -->
<h1 mat-dialog-title>
    URBE - VERIFICACIÓN DE CONSTANCIA
</h1>

<div mat-dialog-content>
    <mat-form-field appearance="outline">
        <mat-label>Nombre</mat-label>
        <input matInput [(ngModel)]="data.nombreCompletoSolic" disabled="true">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Cedula</mat-label>
        <input matInput [(ngModel)]="data.identificacionSolic" disabled="true">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Fecha de Ingreso</mat-label>
        <input matInput [(ngModel)]="data.fechaIngresoSolic" disabled="true">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Cargo</mat-label>
        <input matInput [(ngModel)]="data.cargoOcupadoSolic" disabled="true">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Destinatario</mat-label>
        <input matInput [(ngModel)]="data.destinatario" disabled="true">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Fecha de Solicitud</mat-label>
        <input matInput [(ngModel)]="data.fechaSol" disabled="true">
      </mat-form-field>
</div>

<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onNoClick()">Volver</button>
</div>