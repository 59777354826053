// Angular.
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { Provider } from "@angular/core";

// Interceptors.
import { TokenInterceptor } from "./TokenInterceptor/token-interceptor";



export const interceptorProviders: Provider[] = [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
];
