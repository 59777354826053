<!-- Navbar, este es el encabezado -->
<div class="main-content">
    <mat-toolbar-row>
        <div>
            <nav class="navbar navbar-expand-lg navbar-light navbar-default text-center">
                <img src="../../../../assets/logos/logo-urbe-sinfondo.gif" class="logo-urbe" alt="logo-urbe">
            </nav>
        </div>
    </mat-toolbar-row>
    <!-- Aqui va el contenido, que se divide en dos -->
    <div class="container m-auto">
        <!-- El lado izquierdo del contenido, se ubica la informacion -->
        <div class="row mt-5 mb-5 div1">
            <div class="col-lg-7 text-center align-self-center m-auto">
                <h1 class="display-4">Solicitud de la constancia de trabajo</h1>
                <p class="lead">Esta opcion permite al trabajador solicitar su constancia de trabajo.</p>
                <i class="material-icons i1">
                    work
                </i>
            </div>
            <div class="col-lg-5 align-self-center">
                <!-- El lado derecho del contenido, se ubica el Login -->
                <div class="login-page">
                    <div class="rightSide">
                        <div class="form">
                            <h5 class="title mb-3">Iniciar sesión</h5>
                            <form [formGroup]="form">
                                <mat-form-field appearance="outline" class="mb-2">
                                    <mat-label>Cedula</mat-label>
                                    <input matInput formControlName="usuario" autocomplete="off">
                                    <mat-hint>
                                        <mat-error
                                            *ngIf="form.controls['usuario'].touched && !form.controls['usuario'].valid">
                                            Este campo es requerido
                                        </mat-error>
                                    </mat-hint>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="mb-2">
                                    <mat-label>Clave</mat-label>
                                    <input matInput autocomplete="off" formControlName="clave"
                                        [type]="hide ? 'password' : 'text'" maxlength="18" minlength="8">
                                    <mat-hint>
                                        <mat-error
                                            *ngIf="form.controls['clave'].touched && !form.controls['clave'].valid">
                                            Este campo es requerido
                                        </mat-error>
                                        <mat-error *ngIf="form.controls['clave'].hasError('maxLength')">Máximo 18
                                            caracteres
                                        </mat-error>
                                        <mat-error *ngIf="form.controls['clave'].hasError('minlength')">Mínimo 8
                                            caracteres
                                        </mat-error>
                                    </mat-hint>
                                    <button mat-icon-button matSuffix (click)="hide = !hide"
                                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                        <ng-container *ngIf="hide; else dontHide">
                                            <i class="material-icons i2">
                                                visibility
                                            </i>
                                        </ng-container>
                                        <ng-template #dontHide>
                                            <i class="material-icons i2">
                                                visibility_off
                                            </i>
                                        </ng-template>
                                    </button>
                                </mat-form-field>
                                <div class="form-element">
                                    <button mat-raised-button color="primary" (click)="onSubmit(form.value)"
                                        [disabled]="form.invalid" type="submit" class="button">
                                        Iniciar sesión
                                    </button>
                                </div>
                            </form>
                        </div>
                        <p class="verification" (click)="redirectTo()">Verificación de Constancias</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Pie de pagina -->
    <app-footer></app-footer>
</div>