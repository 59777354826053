import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { MessagesService } from '../../services/messages.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  //Declaracion de las variables que se usan en el componente
  form: FormGroup;
  hide = true;

  constructor(private formbuilder: FormBuilder, private router: Router, private service: AuthenticationService, private message: MessagesService) { 
    this.form = this.formbuilder.group({
      usuario: ['', Validators.required],  // Cedula del trabajador.
      clave: ['', Validators.required]     // Contraseña.
    });
  }

  //Funcion que, envia el formulario al servicio y retorna el permiso para entrar a la pagina de constancia
  onSubmit(form) {
    this.service.login(form).subscribe(
      response => {
        this.service.saveToken(response.headers.get('Authorization'));
        const role: any = this.service.perfil()
          this.router.navigate(['/constancia']);
        }, error =>{
          this.message.errorLoginMessage()
          this.form.reset()
        });
  }

  //Funcion que redirije a la pantalla de verificacion
  redirectTo() {
    this.router.navigate(['/verificacion']);
  }

  ngOnInit(): void {
  }

}
