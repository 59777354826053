import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(private snackbar: MatSnackBar) { }

  //Ambas funciones tienen como mision mostrar mensajes
  errorLoginMessage() {
    this.snackbar.open('El nombre de usuario o contraseña es incorrecto', '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['user-message']
    });
  }

  constancia404() {
    this.snackbar.open('La constancia ingresada no se ha encontrado', '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['user-message-error']
    });
  }

  carga404() {
    this.snackbar.open('La solicitud de constancia de trabajo ha fallado. Intente nuevamente.', '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['user-message-error']
    });
  }


  }

