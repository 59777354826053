import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import decode from 'jwt-decode';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  //variables que se usaran en el servicio
  apiLogin = environment.apiDev;
  tokenKey: string = 'key'
  constructor(private http: HttpClient) { }

  //Funcion que recibe los datos del Login 
  login(body: any) {
    return this.http.post(`${this.apiLogin}/login`, body, {'headers': new HttpHeaders ({'Content-Type': 'application/json'}), observe: 'response'});
  }

  //Funcion que, remueve el token del sessionStorage y limpia
  singOut() {
    sessionStorage.removeItem(this.tokenKey);
    sessionStorage.clear();
  }

  //Funcion que, remueve el token que haya en el sessionStorage y guarda un nuevo token en el
  saveToken(token: string) {
    sessionStorage.removeItem(this.tokenKey);
    sessionStorage.setItem(this.tokenKey, token);
  }

  //Funcion que retorna el Token
  getToken() {
    return sessionStorage.getItem(this.tokenKey);
  }

  //Funcion que decodifica el token para retornar datos del ususario logeado
  perfil() {
    if (sessionStorage.getItem(this.tokenKey)) {
      const token = decode(sessionStorage.getItem(this.tokenKey) as string);
      return token;
    } else {
      return undefined;
    }    
  }

  //Funcion que chequea si existe un token en el sessionStorage o no
  existToken(): boolean {
    let hasToken = false;
    if (sessionStorage.getItem(this.tokenKey)) {
      hasToken = true;
    } else { 
      hasToken = false; 
    };
    return hasToken;
  }

  //Funcion que decodifica el token y re-ordena la informacion
  tokenDecoded() {
    let authToken: any = this.getToken();
    if (authToken != undefined) {
      let auxToken = authToken as string;
      return authToken = jwt_decode(auxToken.replace('Bearer ', ''));
    }
  }

  //Funcion que, usa la respuesta de la funcion anterior y extrae el Nombre y Apellido del usuario
  getName() {
    const authToken: any = this.tokenDecoded();
    let name = "";
    if (authToken != undefined) {
      name = `${authToken.primerNombre.toUpperCase()} ${authToken.primerApellido.toUpperCase()}`;
    }
    return name
  }

}
