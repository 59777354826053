import { Component, OnInit, Input  } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

interface NavBar {
  type: string;
  name: string;
  route: string;
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  @Input() isLogged!: boolean;
  userRole: string = 'none';
  isOpen: boolean = false;
  name: string = '';
  displayedNavbar!: NavBar[];

  constructor(private authenticationService: AuthenticationService,
              private route: Router) { }

  closeNavbar() {
    this.isOpen = !this.isOpen;
    const navs = document.querySelectorAll('.navbar-items');
    navs.forEach(nav => nav.classList.toggle('Navbar__ToggleShow'));
  }
    
  signOut() {
    this.authenticationService.singOut();
    this.route.navigate(['/']);
  }
  
  ngOnInit(): void {
    this.name = this.authenticationService.getName();
  }
}
