// Angular.
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
// RXJS.
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Servicios.
import { AuthenticationService } from '../../services/authentication.service';



@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor( private authenticationService: AuthenticationService ) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        if (this.authenticationService.existToken()) {
            const authReq = req.clone({ setHeaders: { 'Content-Type': 'application/json', 'Authorization': this.authenticationService.getToken() as string }});
            return next.handle(authReq).pipe(
                catchError(( error: HttpErrorResponse ) => {
                    if (error.status === 403) {
                        alert(`${error.error.message}`);
                    };
                        return throwError(() => error);
                }));
        } else {
            return next.handle(req);
        };
    };
}